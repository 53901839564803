import defaultPosition from "@/helpers/wc/positionSettings";

export const deceasedListGravarSettings = {
  title: 'Deceased List Gravar',
  name: 'deceasedListGravarBase',

  styles: defaultPosition,

  properties: {
    cemeteryId: '', // obsolete, keep for backwards compability
    cemeteryIds: ["0000-0000-0000-0000-00000"],
    routeName: "",
    css: {
      caption: {
        fontFamily: "Arial",
        fontSize: '14px',
        height: '20px',
        color: 'black'
      },
      header: {
        fontFamily: "Arial",
        fontSize: 18,
        height: '30px',
        color: 'black',
        backgroundColor: 'white'
      },
      row: {
        fontFamily: "Arial",
        fontSize: 14,
        height: '30px',
        color: 'black',
        backgroundColor: 'white'
      },
      button: {
        fontFamily: "Arial",
        fontSize: 14,
        height: '30px',
        color: 'white',
        backgroundColor: 'limegreen',
        borderColor: 'grey',
        borderRadius: '5px'
      }
    }
  },
  cssLegend: {
    caption: {
      name: "Caption",
      fontFamily: {
        type: "fontFamily",
        name: "Font Family"
      },
      fontSize: {
        type: "int",
        name: "Font Size",
        handler: val => val + 'px'
      },
      height: {
        type: "int",
        name: "Height",
        handler: val => val + 'px'
      },
      color: {
        type: "color",
        name: "Color"
      },
    },
    header: {
      name: "Header",
      fontFamily: {
        type: "fontFamily",
        name: "Font Family"
      },
      fontSize: {
        type: "int",
        name: "Font Size",
        handler: val => val + 'px'
      },
      height: {
        type: "int",
        name: "Height",
        handler: val => val + 'px'
      },
      color: {
        type: "color",
        name: "Color"
      },
      backgroundColor: {
        type: "color",
        name: "Bottom Border"
      }
    },
    row: {
      name: "Item",
      fontFamily: {
        type: "fontFamily",
        name: "Font Family"
      },
      fontSize: {
        type: "int",
        name: "Font Size",
        handler: val => val + 'px'
      },
      height: {
        type: "int",
        name: "Height",
        handler: val => val + 'px'
      },
      color: {
        type: "color",
        name: "Color"
      },
      backgroundColor: {
        type: "color",
        name: "Background"
      }
    },
    button: {
      name: "Button",
      fontFamily: {
        type: "fontFamily",
        name: "Font Family"
      },
      fontSize: {
        type: "int",
        name: "Font Size",
        handler: val => val + 'px'
      },
      height: {
        type: "int",
        name: "Height",
        handler: val => val + 'px'
      },
      color: {
        type: "color",
        name: "Color"
      },
      backgroundColor: {
        type: "color",
        name: "Background"
      },
      borderColor: {
        type: "color",
        name: "Border Color"
      },
      borderRadius: {
        type: "int",
        name: "Border Radius",
        handler: val => val + 'px'
      }
    }
  }
};
