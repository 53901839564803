import { cmsComponents } from "@/helpers/cms/componentsRegister";
import navigationKeys from "@/helpers/cms/navigationKeys";
import siteModules from "@/helpers/cms/siteModules";
import { permissions } from "@/helpers/claims";

export const adminRoutes = {
  path: "/admin",
  component: cmsComponents.mainCms,
  meta: {
    breadcrumbs: [
      {
        name: navigationKeys.admin,
        routeName: 'admin'
      }
    ],
    admin: true,
    login: true
  },
  children: [
    {
      path: ":module/named-content/:serviceId",
      name: "sites-named-content",
      component: cmsComponents.SitesNamedContent,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.sites,
            routeName: "services-all",
          },
          {
            name: navigationKeys.sitesNamedContent,
            routeName: "sites-named-content",
          }
        ],
        parent: "services",
        admin: true,
        login: true
      }
    },
    {
      path: ":module/named-content/:serviceId/:title",
      name: "sites-named-content-edit",
      component: cmsComponents.SitesNamedContentEdit,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.sites,
            routeName: "services",
          },
          {
            name: navigationKeys.sitesNamedContent,
            routeName: "sites-named-content",
          }
        ],
        parent: "services",
        admin: true,
        login: true
      }
    },
    {
      path: "sites",
      name: "services-all",
      component: cmsComponents.CmsContent,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.admin,
            routeName: "admin"
          }
        ],
        admin: true,
        login: true
      },
      children: [
        {
          path: "",
          name: "services-web-constructor",
          component: cmsComponents.Sites,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.sites,
                routeName: "services-web-constructor"
              }
            ],
            permission: [permissions.Sites],
            admin: true,
            login: true
          }
        },
        {
          path: `${siteModules.ProconEvent}`,
          name: "services-procon-event",
          component: cmsComponents.Sites,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.sites,
                routeName: "services-all"
              }
            ],
            permission: [permissions.ProconEvent],
            admin: true,
            login: true
          }
        },
        {
          path: `${siteModules.VisitExpress}`,
          name: "services-visit-express",
          component: cmsComponents.Sites,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.sites,
                routeName: "services-all"
              }
            ],
            permission: [permissions.VisitExpressSites],
            admin: true,
            login: true
          }
        },
        {
          path: `${siteModules.MeetingRoom}`,
          name: "services-meeting-room",
          component: cmsComponents.Sites,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.sites,
                routeName: "services-all"
              }
            ],
            permission: [permissions.MeetingRooms],
            admin: true,
            login: true
          }
        },
        {
          path: `${siteModules.Queue}`,
          name: "services-queue",
          component: cmsComponents.Sites,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.sites,
                routeName: "services-all"
              }
            ],
            permission: [permissions.QueueLines],
            admin: true,
            login: true
          }
        },
      ]
    },
    {
      path: ":module/site-edit/:siteId",
      name: "site-edit",
      component: cmsComponents.InfoSearchSitesEdit,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.sites,
            routeName: 'services-all'
          },
          {
            name: navigationKeys.infoSearchSites,
            routeName: "site-edit",
            editable: true,
          }
        ],
        permission: [permissions.InfoSearchSitesWrite],
        admin: true,
        login: true
      }
    },
    {
      path: "admin",
      component: cmsComponents.CmsContent,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.admin,
            routeName: "admin"
          }
        ],
        admin: true,
        login: true
      },
      children: [
        {
          path: "",
          name: "admin",
          component: cmsComponents.NavigationPanel,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.admin,
                routeName: "admin"
              }
            ],
            admin: true,
            login: true
          },
        },
        {
          path: "companies",
          name: "companies",
          component: cmsComponents.Companies,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.admin,
                routeName: "admin"
              },
              {
                name: navigationKeys.companies,
                routeName: "companies"
              }
            ],
            permission: [permissions.Companies],
            admin: true,
            login: true
          },
        },
        {
          path: "companies/:companyId",
          name: "companies-edit",
          component: cmsComponents.CompaniesEdit,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.admin,
                routeName: "admin"
              },
              {
                name: navigationKeys.companies,
                routeName: "companies"
              },
              {
                name: navigationKeys.companies,
                routeName: "companies",
                editable: true
              }
            ],
            permission: [permissions.Companies],
            admin: true,
            login: true
          },
        },
        {
          path: "companies/:companyId/:parentId",
          name: "companies-add",
          component: cmsComponents.CompaniesEdit,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.admin,
                routeName: "admin"
              },
              {
                name: navigationKeys.companies,
                routeName: "companies"
              },
              {
                name: navigationKeys.companies,
                routeName: "companies",
                editable: true
              }
            ],
            permission: [permissions.CreateCompany],
            admin: true,
            login: true
          },
        },
        {
          path: "logger",
          name: "logger",
          component: cmsComponents.Logger,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.admin,
                routeName: "admin"
              },
              {
                name: navigationKeys.logger,
                routeName: "logger"
              }
            ],
            permission: [permissions.SuperAdmin],
            admin: true,
            login: true
          },
        },
        {
          path: "departments",
          name: "departments",
          component: cmsComponents.Departments,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.admin,
                routeName: "admin"
              },
              {
                name: navigationKeys.dropIns,
                routeName: "departments"
              }
            ],
            permission: [permissions.SuperAdmin],
            admin: true,
            login: true
          },
        },
        {
          path: "departments/:departmentId",
          name: "departments-edit",
          component: cmsComponents.DepartmentsEdit,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.admin,
                routeName: "admin"
              },
              {
                name: navigationKeys.dropIns,
                routeName: "departments"
              }
            ],
            permission: [permissions.DropInDepartmentsWrite],
            admin: true,
            login: true
          },
        },
        {
          path: "users",
          name: "users",
          component: cmsComponents.Users,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.admin,
                routeName: "admin"
              },
              {
                name: navigationKeys.users,
                routeName: "users"
              }
            ],
            permission: [permissions.Users],
            admin: true,
            login: true
          },
        },
        {
          path: "users/:userId",
          name: "users-edit",
          component: cmsComponents.UsersEdit,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.admin,
                routeName: "admin"
              },
              {
                name: navigationKeys.users,
                routeName: "users"
              }
            ],
            permission: [permissions.UserEditYourself],
            admin: true,
            login: true
          },
        },
        {
          path: "user-group",
          name: "user-groups",
          component: cmsComponents.UserGroup,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.admin,
                routeName: "admin"
              },
              {
                name: navigationKeys.userRoles,
                routeName: "user-groups"
              }
            ],
            permission: [permissions.Roles],
            admin: true,
            login: true
          },
        },
        {
          path: "user-group-edit/:roleId",
          name: "user-group-edit",
          component: cmsComponents.UserGroupEdit,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.admin,
                routeName: "admin"
              },
              {
                name: navigationKeys.userRoles,
                routeName: "user-groups"
              },
              {
                name: navigationKeys.userRoles,
                routeName: "user-groups",
                editable: true
              }
            ],
            permission: [permissions.RolesWrite],
            admin: true,
            login: true
          },
        },
      ]
    },
    {
      path: "meetingroomedit/:serviceId",
      name: "meetingroomedit",
      component: cmsComponents.MeetingRoomEdit,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.sites,
            routeName: "services-all"
          },
          {
            name: navigationKeys.meetingRooms,
            routeName: 'meetingroomedit'
          }
        ],
        permission: [permissions.MeetingRoomsWrite],
        admin: true,
        login: true
      }
    },
    {
      path: "meetingEmail/:serviceId",
      name: "meetingEmail",
      component: cmsComponents.MeetingEmail,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.sites,
            routeName: "services-all"
          },
          {
            name: navigationKeys.meetingRooms,
            routeName: 'meetingroomedit'
          }
        ],
        admin: true,
        login: true
      }
    },
    {
      path: "meetings/:serviceId",
      name: "meetings",
      component: cmsComponents.Meetings,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.sites,
            routeName: "services-all"
          },
          {
            name: navigationKeys.meetings,
            routeName: 'meetings',
            editable: true
          }
        ],
        admin: true,
        login: true
      }
    },
    {
      path: "meetings-log/:email",
      name: "meetings-log",
      component: cmsComponents.MeetingsLog,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.sites,
            routeName: "services-all"
          },
          {
            name: navigationKeys.meetings,
            routeName: 'meetings',
            editable: true
          }
        ],
        admin: true,
        login: true
      }
    },
    {
      path: "login",
      name: "login",
      component: cmsComponents.Login,
      meta: {
        admin: true,
        login: false
      }
    },
    {
      path: "visitor/:serviceId",
      name: "active-visitors",
      component: cmsComponents.ActiveVisitors,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.sites,
            routeName: "services-all"
          },
          {
            name: navigationKeys.userActivities,
            routeName: "visitor",
          }
        ],
        permission: [permissions.Visitors],
        admin: true,
        login: true
      }
    },
    {
      path: "templates",
      name: "templates",
      component: cmsComponents.Templates,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.templates,
            routeName: "templates"
          }
        ],
        permission: [permissions.Templates],
        admin: true,
        login: true
      },
    },
    {
      path: "templates/:templateId",
      name: "edit-template",
      component: cmsComponents.EditTemplate,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.templates,
            routeName: "templates"
          },
          {
            name: navigationKeys.templates,
            routeName: "templates",
            editable: true
          }
        ],
        permission: [permissions.TemplatesWrite],
        admin: true,
        login: true
      },
      children: [
      ]
    },
    {
      path: "pages",
      name: "template-pages",
      component: cmsComponents.TemplatePages,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.templates,
            routeName: 'template-pages'
          }
        ],
        admin: true,
        login: true
      },
    },
    {
      path: "pages/:pageId/editor",
      name: "template-page-editor",
      component: cmsComponents.TemplateEditor,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.templates,
            routeName: 'template-pages'
          }
        ],
        admin: true,
        login: true
      }
    },
    {
      path: "site/:module?/:siteId/pages",
      name: "site-pages",
      component: cmsComponents.SitePages,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.sites,
            routeName: "services-web-constructor"
          },
          {
            name: navigationKeys.sites,
            routeName: "services-all",
            editable: true
          },
          {
            name: navigationKeys.sitePages,
            routeName: 'site-pages'
          }
        ],
        admin: true,
        login: true
      }
    },
    {
      path: ":module?/site/:siteId/pages/:pageId/editor",
      name: "page-editor",
      component: cmsComponents.Editor,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.sitePages,
            routeName: 'page-editor'
          }
        ],
        admin: true,
        login: true
      }
    },

    // company admin routes
    {
      path: "company-admin",
      component: cmsComponents.CmsContent,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.companyAdmin,
            routeName: 'company-admin'
          }
        ],
        admin: true,
        login: true
      },
      children: [
        {
          path: "",
          name: "company-admin",
          component: cmsComponents.NavigationPanel,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.companyAdmin,
                routeName: "company-admin"
              }
            ],
            admin: true,
            login: true
          },
        },
        {
          path: "admin-departments",
          name: "admin-departments",
          component: cmsComponents.AdminDepartments,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.companyAdmin,
                routeName: "company-admin"
              },
              {
                name: navigationKeys.adminDepartments,
                routeName: "admin-departments"
              },
            ],
            permission: [permissions.AdminDropInDepartments],
            admin: true,
            login: true
          },
        },
        {
          path: "admin-departments/:departmentId",
          name: "admin-departments-edit",
          component: cmsComponents.AdminDepartmentsEdit,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.companyAdmin,
                routeName: "company-admin"
              },
              {
                name: navigationKeys.adminDepartments,
                routeName: "admin-departments"
              },
            ],
            permission: [permissions.AdminDropInDepartmentsWrite],
            admin: true,
            login: true
          },
        },
        {
          path: "job-departments",
          name: "job-departments",
          component: cmsComponents.JobDepartments,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.companyAdmin,
                routeName: "company-admin"
              },
              {
                name: navigationKeys.jobDepartments,
                routeName: "job-departments"
              }
            ],
            permission: [permissions.JobDepartments],
            admin: true,
            login: true
          },
        },
        {
          path: "job-departments/:categoryId",
          name: "job-departments-edit",
          component: cmsComponents.JobDepartmentsEdit,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.companyAdmin,
                routeName: "company-admin"
              },
              {
                name: navigationKeys.jobDepartments,
                routeName: "job-departments"
              },
              {
                name: navigationKeys.jobDepartments,
                routeName: "job-departments-edit",
              }
            ],
            permission: [permissions.JobDepartmentsWrite],
            admin: true,
            login: true
          },
        },
        {
          path: "job-titles",
          name: "job-titles",
          component: cmsComponents.JobTitles,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.companyAdmin,
                routeName: "company-admin"
              },
              {
                name: navigationKeys.jobTitles,
                routeName: "job-titles"
              }
            ],
            permission: [permissions.JobTitles],
            admin: true,
            login: true
          },
        },
        {
          path: "multi-queue",
          name: "multi-queue",
          component: cmsComponents.CmsContent,
          meta: {
            permission: [permissions.MultiQueue],
            admin: true,
            login: true
          },
          children: [
            {
              path: "settings",
              name: "multi-queue-settings",
              component: cmsComponents.MultiQueue,
              meta: {
                breadcrumbs: [
                  {
                    name: navigationKeys.companyAdmin,
                    routeName: "company-admin"
                  },
                  {
                    name: navigationKeys.MultiQueueSetting,
                    routeName: "multi-queue-settings"
                  }
                ],
                permission: [permissions.MultiQueueSettings],
                admin: true,
                login: true
              },
            },
          ]
        },
        {
          path: "job-titles/:jobTitleId",
          name: "job-titles-edit",
          component: cmsComponents.JobTitlesEdit,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.companyAdmin,
                routeName: "company-admin"
              },
              {
                name: navigationKeys.jobTitles,
                routeName: "job-titles-edit"
              },
              {
                name: navigationKeys.jobTitles,
                routeName: "job-titles-edit",
              }
            ],
            permission: [permissions.JobTitlesWrite],
            admin: true,
            login: true
          },
        },
        {
          path: "admin-companies",
          name: "admin-companies",
          component: cmsComponents.AdminCompanies,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.companyAdmin,
                routeName: "company-admin"
              },
              {
                name: navigationKeys.companies,
                routeName: "admin-companies"
              }
            ],
            admin: true,
            login: true
          }
        },
        {
          path: "admin-companies/:companyId",
          name: "admin-companies-edit",
          component: cmsComponents.AdminCompaniesEdit,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.companyAdmin,
                routeName: "company-admin"
              },
              {
                name: navigationKeys.companies,
                routeName: "admin-companies"
              },
              {
                name: navigationKeys.companies,
                routeName: "admin-companies-edit",
                editable: true
              }
            ],
            admin: true,
            login: true
          }
        },
        {
          path: "employees",
          name: "employees",
          component: cmsComponents.CompanyUsers,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.companyAdmin,
                routeName: "company-admin"
              },
              {
                name: navigationKeys.employees,
                routeName: "employees"
              },
            ],
            permission: [permissions.Employees],
            admin: true,
            login: true
          }
        },
        {
          path: "employees/:userId",
          name: "employees-edit",
          component: cmsComponents.CompanyUsersEdit,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.companyAdmin,
                routeName: "company-admin"
              },
              {
                name: navigationKeys.employees,
                routeName: "employees"
              },
            ],
            permission: [permissions.EmployeesWriteExternal],
            admin: true,
            login: true
          }
        },
        {
          path: "dropin",
          name: "dropin",
          component: cmsComponents.DropinUsers,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.companyAdmin,
                routeName: "company-admin"
              },
              {
                name: navigationKeys.dropIns,
                routeName: "dropin"
              }
            ],
            admin: true,
            login: true
          }
        },
        {
          path: "dropin/:userId",
          name: "dropin-edit",
          component: cmsComponents.DropinUsersEdit,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.companyAdmin,
                routeName: "company-admin"
              },
              {
                name: navigationKeys.dropIns,
                routeName: "dropin"
              },
              {
                name: navigationKeys.dropIns,
                routeName: "dropin-edit",
              },
            ],
            admin: true,
            login: true
          }
        },
        {
          path: "admin-messages",
          component: cmsComponents.CmsContent,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.companyAdmin,
                routeName: "company-admin"
              },
              {
                name: navigationKeys.companyAdminMessages,
                routeName: "admin-messages"
              }
            ],
            admin: true,
            login: true
          },
          children: [
            {
              path: "",
              name: "admin-messages",
              component: cmsComponents.NavigationPanel,
              meta: {
                breadcrumbs: [
                  {
                    name: navigationKeys.companyAdmin,
                    routeName: "company-admin"
                  },
                  {
                    name: navigationKeys.companyAdminMessages,
                    routeName: "admin-messages"
                  },
                ],
                admin: true,
                login: true
              }
            },
            {
              path: "employees-messages",
              name: "employees-messages",
              component: cmsComponents.CompanyMessages,
              meta: {
                breadcrumbs: [
                  {
                    name: navigationKeys.companyAdmin,
                    routeName: "company-admin"
                  },
                  {
                    name: navigationKeys.companyAdminMessages,
                    routeName: "admin-messages"
                  },
                  {
                    name: navigationKeys.employeeNotifications,
                    routeName: "employees-messages",
                  },
                ],
                permission: [permissions.EmployeeNotifications],
                admin: true,
                login: true
              }
            },
            {
              path: "dropin-messages",
              name: "dropin-messages",
              component: cmsComponents.DropinMessages,
              meta: {
                breadcrumbs: [
                  {
                    name: navigationKeys.companyAdmin,
                    routeName: "company-admin"
                  },
                  {
                    name: navigationKeys.companyAdminMessages,
                    routeName: "admin-messages"
                  },
                  {
                    name: navigationKeys.dropInNotifications,
                    routeName: "dropin-messages",
                  },
                ],
                permission: [permissions.DropInNotifications],
                admin: true,
                login: true
              }
            },
            {
              path: "dropin-messages/:messageId",
              name: "dropin-messages-edit",
              component: cmsComponents.DropinMessagesEdit,
              meta: {
                breadcrumbs: [
                  {
                    name: navigationKeys.companyAdmin,
                    routeName: "company-admin"
                  },
                  {
                    name: navigationKeys.companyAdminMessages,
                    routeName: "admin-messages"
                  },
                  {
                    name: navigationKeys.dropInNotifications,
                    routeName: "dropin-messages-edit",
                  }
                ],
                permission: [permissions.DropInNotificationsWrite],
                admin: true,
                login: true
              }
            },
            {
              path: "visitor-messages",
              name: "visitor-messages",
              component: cmsComponents.VisitorMessages,
              meta: {
                breadcrumbs: [
                  {
                    name: navigationKeys.companyAdmin,
                    routeName: "company-admin"
                  },
                  {
                    name: navigationKeys.companyAdminMessages,
                    routeName: "admin-messages"
                  },
                  {
                    name: navigationKeys.visitorNotifications,
                    routeName: "visitor-messages",
                  },
                ],
                permission: [permissions.VisitorNotifications],
                admin: true,
                login: true
              }
            },
            {
              path: "evacuation-messages",
              name: "evacuation-messages",
              component: cmsComponents.EvacuationMessages,
              meta: {
                breadcrumbs: [
                  {
                    name: navigationKeys.companyAdmin,
                    routeName: "company-admin"
                  },
                  {
                    name: navigationKeys.companyAdminMessages,
                    routeName: "admin-messages"
                  },
                  {
                    name: navigationKeys.evacuationMessages,
                    routeName: "evacuation-messages",
                  },
                ],
                permission: [permissions.EvacuationMessages],
                admin: true,
                login: true
              }
            },
            {
              path: "visitexpress-messages",
              name: "visitexpress-messages",
              component: cmsComponents.VisitexpressMessages,
              meta: {
                breadcrumbs: [
                  {
                    name: navigationKeys.companyAdmin,
                    routeName: "company-admin"
                  },
                  {
                    name: navigationKeys.companyAdminMessages,
                    routeName: "admin-messages"
                  },
                  {
                    name: navigationKeys.visitexpressMessages,
                    routeName: "visitexpress-messages",
                  },
                ],
                permission: [permissions.VisitExpress],
                admin: true,
                login: true
              }
            },
            {
              path: "preregistration-messages",
              name: "preregistration-messages",
              component: cmsComponents.PreregistrationMessages,
              meta: {
                breadcrumbs: [
                  {
                    name: navigationKeys.companyAdmin,
                    routeName: "company-admin"
                  },
                  {
                    name: navigationKeys.companyAdminMessages,
                    routeName: "admin-messages"
                  },
                  {
                    name: navigationKeys.preregistrationMessages,
                    routeName: "preregistration-messages",
                  },
                ],
                permission: [permissions.ProconEvent],
                admin: true,
                login: true
              }
            },
          ]
        },
        {
          path: "admin-company-settings",
          component: cmsComponents.CmsContent,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.companyAdmin,
                routeName: "company-admin"
              },
              {
                name: navigationKeys.companyAdminSettings,
                routeName: "admin-company-settings"
              }
            ],
            admin: true,
            login: true
          },
          children: [
            {
              path: "",
              name: "admin-company-settings",
              component: cmsComponents.NavigationPanel,
              meta: {
                breadcrumbs: [
                  {
                    name: navigationKeys.companyAdmin,
                    routeName: "company-admin"
                  },
                  {
                    name: navigationKeys.companyAdminSettings,
                    routeName: "admin-company-settings"
                  }
                ],
                admin: true,
                login: true
              }
            },
            {
              path: "azure-ad/:companyId?",
              name: "azure-ad",
              component: cmsComponents.CompanySettings,
              meta: {
                breadcrumbs: [
                  {
                    name: navigationKeys.companyAdmin,
                    routeName: "company-admin"
                  },
                  {
                    name: navigationKeys.companyAdminSettings,
                    routeName: "admin-company-settings"
                  },
                  {
                    name: navigationKeys.azureAd,
                    routeName: "azure-ad",
                  },
                ],
                permission: [permissions.AzureAD],
                admin: true,
                login: true
              }
            },
            {
              path: "telenor/:companyId?",
              name: "telenor",
              component: cmsComponents.CompanySettings,
              meta: {
                breadcrumbs: [
                  {
                    name: navigationKeys.companyAdmin,
                    routeName: "company-admin"
                  },
                  {
                    name: navigationKeys.companyAdminSettings,
                    routeName: "admin-company-settings"
                  },
                  {
                    name: navigationKeys.telenor,
                    routeName: "telenor",
                  },
                ],
                permission: [permissions.CustomEmployeeSync],
                admin: true,
                login: true
              }
            },
            {
              path: "visitor-persistance/:companyId?",
              name: "visitor-persistance",
              component: cmsComponents.CompanySettings,
              meta: {
                breadcrumbs: [
                  {
                    name: navigationKeys.companyAdmin,
                    routeName: "company-admin"
                  },
                  {
                    name: navigationKeys.companyAdminSettings,
                    routeName: "admin-company-settings"
                  },
                  {
                    name: navigationKeys.visitorPersistance,
                    routeName: "visitor-persistance",
                  },
                ],
                permission: [permissions.CompanySettings],
                admin: true,
                login: true
              }
            },
            {
              path: "visitors-custom/:companyId?",
              name: "visitors-custom-fields",
              component: cmsComponents.CompanySettings,
              meta: {
                breadcrumbs: [
                  {
                    name: navigationKeys.companyAdmin,
                    routeName: "company-admin"
                  },
                  {
                    name: navigationKeys.companyAdminSettings,
                    routeName: "admin-company-settings"
                  },
                  {
                    name: navigationKeys.visitorsCustomFields,
                    routeName: "visitors-custom-fields",
                  },
                ],
                permission: [permissions.CompanySettings],
                admin: true,
                login: true
              }
            },
            {
              path: "company-agreement/:companyId?",
              name: "company-agreement",
              component: cmsComponents.CompanySettings,
              meta: {
                breadcrumbs: [
                  {
                    name: navigationKeys.companyAdmin,
                    routeName: "company-admin"
                  },
                  {
                    name: navigationKeys.companyAdminSettings,
                    routeName: "admin-company-settings"
                  },
                  {
                    name: navigationKeys.companyAgreement,
                    routeName: "company-agreement",
                  },
                ],
                permission: [permissions.CompanyAgreement],
                admin: true,
                login: true
              }
            },
            {
              path: "sms-service/:companyId?",
              name: "sms-service",
              component: cmsComponents.CompanySettings,
              meta: {
                breadcrumbs: [
                  {
                    name: navigationKeys.companyAdmin,
                    routeName: "company-admin"
                  },
                  {
                    name: navigationKeys.companyAdminSettings,
                    routeName: "admin-company-settings"
                  },
                  {
                    name: navigationKeys.smsService,
                    routeName: "sms-service",
                  },
                ],
                permission: [permissions.SMSsettings],
                admin: true,
                login: true
              }
            },
            {
              path: "email-service/:companyId?",
              name: "email-service",
              component: cmsComponents.CompanySettings,
              meta: {
                breadcrumbs: [
                  {
                    name: navigationKeys.companyAdmin,
                    routeName: "company-admin"
                  },
                  {
                    name: navigationKeys.companyAdminSettings,
                    routeName: "admin-company-settings"
                  },
                  {
                    name: navigationKeys.emailService,
                    routeName: "email-service",
                  },
                ],
                permission: [permissions.EmailSettings],
                admin: true,
                login: true
              }
            },
            {
              path: "meeting-description/:companyId?",
              name: "meeting-description",
              component: cmsComponents.CompanySettings,
              meta: {
                breadcrumbs: [
                  {
                    name: navigationKeys.companyAdmin,
                    routeName: "company-admin"
                  },
                  {
                    name: navigationKeys.companyAdminSettings,
                    routeName: "admin-company-settings"
                  },
                  {
                    name: navigationKeys.meetingDescription,
                    routeName: "meeting-description",
                  },
                ],
                permission: [permissions.CompanySettings],
                admin: true,
                login: true
              }
            },
            {
              path: "custom-links/:companyId?",
              name: "custom-links",
              component: cmsComponents.CompanySettings,
              meta: {
                breadcrumbs: [
                  {
                    name: navigationKeys.companyAdmin,
                    routeName: "company-admin"
                  },
                  {
                    name: navigationKeys.companyAdminSettings,
                    routeName: "admin-company-settings"
                  },
                  {
                    name: navigationKeys.customLinks,
                    routeName: "custom-links",
                  },
                ],
                permission: [permissions.CompanySettings],
                admin: true,
                login: true
              }
            },
            {
              path: "logo-settings/:companyId?",
              name: "logo-settings",
              component: cmsComponents.CompanySettings,
              meta: {
                breadcrumbs: [
                  {
                    name: navigationKeys.companyAdmin,
                    routeName: "company-admin"
                  },
                  {
                    name: navigationKeys.companyAdminSettings,
                    routeName: "admin-company-settings"
                  },
                  {
                    name: navigationKeys.logo,
                    routeName: "logo-settings",
                  },
                ],
                permission: [permissions.CompanyLogo],
                admin: true,
                login: true
              }
            },
            {
              path: "kiosk-admin-settings/:companyId?",
              name: "kiosk-admin-settings",
              component: cmsComponents.CompanySettings,
              meta: {
                breadcrumbs: [
                  {
                    name: navigationKeys.companyAdmin,
                    routeName: "company-admin"
                  },
                  {
                    name: navigationKeys.companyAdminSettings,
                    routeName: "admin-company-settings"
                  },
                  {
                    name: navigationKeys.kioskAdmin,
                    routeName: "kiosk-admin-settings",
                  },
                ],
                permission: [permissions.CompanySettings],
                admin: true,
                login: true
              }
            },
            {
              path: "easy-park-settings/:companyId?",
              name: "easy-park-settings",
              component: cmsComponents.CompanySettings,
              meta: {
                breadcrumbs: [
                  {
                    name: navigationKeys.companyAdmin,
                    routeName: "company-admin"
                  },
                  {
                    name: navigationKeys.companyAdminSettings,
                    routeName: "admin-company-settings"
                  },
                  {
                    name: navigationKeys.easyParkSettings,
                    routeName: "easy-park-settings",
                  },
                ],
                permission: [permissions.CompanySettings],
                admin: true,
                login: true
              }
            },
            {
              path: "multi-queue-custom-settings/:companyId?",
              name: "multi-queue-custom-settings",
              component: cmsComponents.CompanySettings,
              meta: {
                breadcrumbs: [
                  {
                    name: navigationKeys.companyAdmin,
                    routeName: "company-admin"
                  },
                  {
                    name: navigationKeys.companyAdminSettings,
                    routeName: "admin-company-settings"
                  },
                  {
                    name: navigationKeys.MultiQueueMenu,
                    routeName: "multi-queue-custom-settings",
                  },
                ],
                permission: [permissions.MultiQueueSettings],
                admin: true,
                login: true
              }
            }
          ]
        }
      ]
    },
    {
      path: "statistics",
      name: "statistics",
      component: cmsComponents.Statistics,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.statistics,
            routeName: "statistics"
          }
        ],
        permission: [permissions.Statistics],
        admin: true,
        login: true
      }
    },
    {
      path: "settings",
      component: cmsComponents.CmsContent,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.settings,
            routeName: "settings"
          }
        ],
        admin: true,
        login: true
      },
      children: [
        {
          path: "",
          name: "settings",
          component: cmsComponents.NavigationPanel,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.settings,
                routeName: "settings"
              }
            ],
            admin: true,
            login: true
          }
        },
        {
          path: "lang-dictionary",
          name: "lang-dictionary",
          component: cmsComponents.LangDictionary,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.settings,
                routeName: "settings"
              },
              {
                name: navigationKeys.langDictionary,
                routeName: "lang-dictionary"
              }
            ],
            parent: "pre-register-meetings",
            permission: [permissions.Companies],
            admin: true,
            login: true
          }
        },
        {
          path: "lang-dictionary/:phraseKeyId",
          name: "lang-dictionary-edit",
          component: cmsComponents.LangDictionaryEdit,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.settings,
                routeName: "settings"
              },
              {
                name: navigationKeys.langDictionary,
                routeName: "lang-dictionary"
              }
            ],
            parent: "pre-register-meetings",
            permission: [permissions.Companies],
            admin: true,
            login: true
          }
        },
        {
          path: "settings-sites",
          name: "settings-sites",
          component: cmsComponents.SiteSettings,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.settings,
                routeName: "settings"
              },
              {
                name: navigationKeys.siteSettings,
                routeName: "settings-sites"
              }
            ],
            permission: [permissions.SiteSettings],
            admin: true,
            login: true
          }
        },
        {
          path: "versions",
          name: "versions",
          component: cmsComponents.Versions,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.settings,
                routeName: "settings"
              },
              {
                name: navigationKeys.versions,
                routeName: "versions"
              }
            ],
            permission: [permissions.Companies],
            admin: true,
            login: true
          }
        },
        {
          path: "login-picture-settings",
          name: "login-picture-settings",
          component: cmsComponents.LoginPictureSettings,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.settings,
                routeName: "settings"
              },
              {
                name: navigationKeys.loginPictureSettings,
                routeName: "login-picture-settings"
              }
            ],
            permission: [permissions.Companies],
            admin: true,
            login: true
          }
        },
      ]
    },
    {
      path: "infosearch",
      component: cmsComponents.CmsContent,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.infoSearch,
            routeName: "infosearch"
          },
        ],
        admin: true,
        login: true
      },
      children: [
        {
          path: "",
          name: "infosearch",
          component: cmsComponents.NavigationPanel,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.infoSearch,
                routeName: "infosearch"
              },
            ],
            admin: true,
            login: true
          }
        },
        {
          path: "sites",
          name: "infosearch-sites",
          component: cmsComponents.InfoSearchSites,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.infoSearch,
                routeName: "infosearch"
              },
              {
                name: navigationKeys.infoSearchSites,
                routeName: "infosearch-sites"
              },
            ],
            permission: [permissions.InfoSearchSites],
            admin: true,
            login: true
          }
        },
        {
          path: "infosearch-patients",
          name: "infosearch-patients",
          component: cmsComponents.InfoSearchPatients,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.infoSearch,
                routeName: "infosearch"
              },
              {
                name: navigationKeys.infoSearchPatients,
                routeName: "infosearch-patients"
              },
            ],
            permission: [permissions.InfoSearchPatients],
            admin: true,
            login: true
          }
        },
        {
          path: "infosearch-patients-edit/:patientId",
          name: "infosearch-patients-edit",
          component: cmsComponents.InfoSearchPatientsEdit,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.infoSearch,
                routeName: "infosearch"
              },
              {
                name: navigationKeys.infoSearchPatients,
                routeName: "infosearch-patients"
              }
            ],
            permission: [permissions.InfoSearchPatientsWrite],
            admin: true,
            login: true
          }
        },
        {
          path: "infosearch-areas",
          name: "infosearch-areas",
          component: cmsComponents.InfoSearchAreas,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.infoSearch,
                routeName: "infosearch"
              },
              {
                name: navigationKeys.infoSearchAreas,
                routeName: "infosearch-areas"
              },
            ],
            permission: [permissions.InfoSearchAreas],
            admin: true,
            login: true
          }
        },
        {
          path: "infosearch-areas-edit/:areaId",
          name: "infosearch-areas-edit",
          component: cmsComponents.InfoSearchAreasEdit,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.infoSearch,
                routeName: "infosearch"
              },
              {
                name: navigationKeys.infoSearchAreas,
                routeName: "infosearch-areas"
              },
            ],
            permission: [permissions.InfoSearchAreasWrite],
            admin: true,
            login: true
          }
        },
        {
          path: "infosearch-categories",
          name: "infosearch-categories",
          component: cmsComponents.InfoSearchCategory,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.infoSearch,
                routeName: "infosearch"
              },
              {
                name: navigationKeys.infoSearchCategories,
                routeName: "infosearch-categories"
              },
            ],
            permission: [permissions.InfoSearch],
            admin: true,
            login: true
          }
        },
        {
          path: "infosearch-category-edit/:categoryId",
          name: "infosearch-category-edit",
          component: cmsComponents.InfoSearchCategoryEdit,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.infoSearch,
                routeName: "infosearch"
              },
              {
                name: navigationKeys.infoSearchCategories,
                routeName: "infosearch-categories"
              },
              {
                name: "",
                routeName: "infosearch-category-edit"
              },
            ],
            permission: [permissions.InfoSearchWrite],
            admin: true,
            login: true
          }
        },
        {
          path: "infosearch-statistics",
          name: "infosearch-statistics",
          component: cmsComponents.InfoSearchStatistics,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.infoSearch,
                routeName: "infosearch"
              },
              {
                name: navigationKeys.infoSearchStatistics,
                routeName: "infosearch-statistics"
              },
            ],
            permission: [permissions.InfoSearch],
            admin: true,
            login: true
          }
        },
      ]
    },
    {
      path: "queue-system-lines",
      name: "queue-system-lines",
      component: cmsComponents.QueueSystemLines,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.queue,
            routeName: "queue-system-lines"
          },
        ],
        permission: [permissions.QueueLines],
        parent: "queue-system",
        admin: true,
        login: true
      }
    },
    {
      path: "queue-system-lines-edit/:queueId",
      name: "queue-system-lines-edit",
      component: cmsComponents.QueueSystemLinesEdit,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.queue,
            routeName: "queue-system-lines"
          }
        ],
        permission: [permissions.QueueLinesWrite],
        parent: "queue-system",
        admin: true,
        login: true
      }
    },
    {
      path: "queue-system-lines-manage/:queueId",
      name: "queue-system-lines-manage",
      component: cmsComponents.QueueSystemLinesManage,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.queue,
            routeName: "queue-system-lines"
          }
        ],
        permission: [permissions.QueueLinesWrite],
        parent: "queue-system",
        admin: true,
        login: true
      }
    },
    {
      path: "entur-lines",
      name: "entur-lines",
      component: cmsComponents.EnturLines,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.enturLines,
            routeName: "entur-lines"
          },
        ],
        permission: [permissions.Entur],
        parent: "entur",
        admin: true,
        login: true
      }
    },
    {
      path: "entur-line-edit/:id",
      name: "entur-line-edit",
      component: cmsComponents.EnturLineEdit,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.enturLineEdit,
            routeName: "entur-line-edit"
          }
        ],
        permission: [permissions.EnturWrite],
        parent: "entur-lines",
        admin: true,
        login: true
      }
    },
    {
      path: "pre-register-meetings",
      name: "pre-register-meetings",
      component: cmsComponents.PreRegisterMeetings,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.preRegisterMeetings,
            routeName: "pre-register-meetings"
          }
        ],
        permission: [permissions.ProconEvent],
        parent: "pre-register-meeting",
        admin: true,
        login: true
      }
    },
    {
      path: "pre-register-meeting/:id",
      name: "pre-register-meeting",
      component: cmsComponents.PreRegisterMeetingEdit,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.preRegisterMeetings,
            routeName: "pre-register-meetings"
          },
          {
            name: navigationKeys.preRegisterMeetings,
            routeName: "pre-register-meeting",
            editable: true
          }
        ],
        permission: [permissions.ProconEvent],
        parent: "pre-register-meetings",
        admin: true,
        login: true
      }
    },
    {
      path: "pre-register-status/:id",
      name: "pre-register-status",
      component: cmsComponents.PreRegisterMeetingStatus,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.preRegisterMeetings,
            routeName: "pre-register-meetings"
          },
          {
            name: navigationKeys.preRegisterMeetingStatus,
            routeName: "pre-register-status"
          }
        ],
        permission: [permissions.ProconEvent],
        parent: "pre-register-meetings",
        admin: true,
        login: true
      }
    },
    {
      path: "redirect/:id",
      name: "redirect",
      component: cmsComponents.Redirect,
      meta: {
        admin: true,
        login: false
      }
    },
    {
      path: "visitorsCustom",
      name: "visitors-custom",
      component: cmsComponents.VisitorsCustom,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.visitorsCustom,
            routeName: "visitors-custom"
          },
        ],
        permission: [permissions.VisitorsList],
        admin: true,
        login: true
      },
    },
    {
      path: "kiosks",
      name: "kiosks",
      component: cmsComponents.Kiosks,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.kiosks,
            routeName: "kiosks"
          }
        ],
        permission: [permissions.KioskMonitor],
        admin: true,
        login: true
      },
    },
  ]
};
