import componentKeys from "@/helpers/wc/componentKeys";
import defaultPosition from "@/helpers/wc/positionSettings";
import textAlignProperties from "@/helpers/wc/textAlignProperties";
import textTags from "@/helpers/wc/textTags";

export const deceasedDetails = {
  title: 'Deceased Details',
  [componentKeys.name]: 'deceasedDetailsBase',

  [componentKeys.styles]: {
    ...defaultPosition,
    [componentKeys.fontSize]: '12px',
    [componentKeys.fontWeight]: 400,
    [componentKeys.color]: "#000",
    [componentKeys.textAlign]: textAlignProperties.center.value,
    [componentKeys.fontFamily]: "sans-serif",
    [componentKeys.lineHeight]: null,
    overflow: 'visible',
    nameFontSize: 30,
    namePadding: 30,
  },
  [componentKeys.properties]: {
    [componentKeys.tag]: textTags.none.value,
    [componentKeys.isComputed]: false,
  }
};
